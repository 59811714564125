/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/4 16:31
 * @description: 标签路由
 */

const tagRouter = {
  route: null,
  name: null,
  title: '标签管理',
  type: 'folder',
  icon: 'iconfont el-icon-collection-tag',
  filePath: 'view/tag/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '标签列表',
      type: 'view',
      name: 'Tag',
      route: '/tag/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/tag/tag-list.vue',
      inNav: true,
      permission: ['商品标签分页查询'],
    },
    {
      title: '创建标签',
      type: 'view',
      name: 'TagCreate',
      route: '/tag/add',
      icon: 'iconfont icon-add',
      filePath: 'view/tag/tag-create.vue',
      inNav: true,
      permission: ['创建商品标签'],
    },
  ],
}

export default tagRouter
