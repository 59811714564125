const bannerRouter = {
  route: null,
  name: null,
  title: 'Banner管理',
  type: 'folder',
  icon: 'iconfont el-icon-collection-tag',
  filePath: 'view/banner/',
  sort: null,
  inNav: true,
  children: [
    {
      title: 'Banner列表',
      type: 'view',
      name: 'Banner',
      route: '/banner/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/banner/banner-list.vue',
      inNav: true,
      permission: ['轮播图分页查询'],
    },
    {
      title: '创建Banner',
      type: 'view',
      name: 'BannerCreate',
      route: '/banner/add',
      icon: 'iconfont icon-add',
      filePath: 'view/banner/banner-create.vue',
      inNav: true,
      permission: ['创建轮播图'],
    },
  ],
}

export default bannerRouter
