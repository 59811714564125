/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/4 15:01
 * @description: 宫格分类路由
 */

const gridCategoryRouter = {
  route: null,
  name: null,
  title: '六宫格管理',
  type: 'folder',
  icon: 'iconfont el-icon-s-grid',
  filePath: 'view/grid-category/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '六宫格列表',
      type: 'view',
      name: 'GridCategory',
      route: '/grid-category/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/grid-category/grid-category-list.vue',
      inNav: true,
      permission: ['宫格分类分页查询'],
    },
    {
      title: '创建六宫格',
      type: 'view',
      name: 'GridCategoryCreate',
      route: '/grid-category/add',
      icon: 'iconfont icon-add',
      filePath: 'view/grid-category/grid-category-create.vue',
      inNav: true,
      permission: ['创建宫格分类'],
    },
  ],
}

export default gridCategoryRouter
