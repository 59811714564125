/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/6/12 13:30
 * @description: 主题路由
 */

const themeRouter = {
  route: null,
  name: null,
  title: '主题管理',
  type: 'folder',
  icon: 'iconfont el-icon-s-marketing',
  filePath: 'view/theme/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '主题列表',
      type: 'view',
      name: 'Theme',
      route: '/theme/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/theme/theme-list.vue',
      inNav: true,
      permission: ['主题分页查询'],
    },
    {
      title: '创建主题',
      type: 'view',
      name: 'ThemeCreate',
      route: '/theme/add',
      icon: 'iconfont icon-add',
      filePath: 'view/theme/theme-create.vue',
      inNav: true,
      permission: ['创建主题'],
    },
  ],
}

export default themeRouter
