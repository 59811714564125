/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/6/30 14:17
 * @description: 辅助功能（上传图片获取地址）路由
 */

const moreRouter = {
  route: null,
  name: null,
  title: '辅助功能',
  type: 'folder',
  icon: 'iconfont el-icon-more',
  filePath: 'view/more/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '图片上传',
      type: 'view',
      name: 'UploadImage',
      route: '/more/more-image',
      icon: 'iconfont el-icon-upload',
      filePath: 'view/more/upload-image.vue',
      inNav: true,
    },
  ],
}

export default moreRouter
