/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/9/20 16:50
 * @description: 商品分类路由
 */

const categoryRouter = {
  route: null,
  name: null,
  title: '分类管理',
  type: 'folder',
  icon: 'iconfont el-icon-crop',
  filePath: 'view/category/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '分类列表',
      type: 'view',
      name: 'Category',
      route: '/category/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/category/category-list.vue',
      inNav: true,
      permission: ['商品分类分页查询'],
    },
    {
      title: '创建分类',
      type: 'view',
      name: 'CategoryCreate',
      route: '/category/add',
      icon: 'iconfont icon-add',
      filePath: 'view/category/category-create.vue',
      inNav: true,
      permission: ['创建商品分类'],
    },
    {
      title: '二级分类列表',
      type: 'tab',
      name: 'subCategoryList',
      route: '/sub-category/:id/list',
      filePath: 'view/category/sub-category-list.vue',
      inNav: false,
      icon: '',
      permission: ['商品分类分页查询'],
    },
  ],
}

export default categoryRouter
