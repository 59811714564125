/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/31 11:16
 * @description: spu路由
 */

const spuRouter = {
  route: null,
  name: null,
  title: 'SPU管理',
  type: 'folder',
  icon: 'iconfont el-icon-s-goods',
  filePath: 'view/spu/',
  sort: null,
  inNav: true,
  children: [
    {
      title: 'SPU列表',
      type: 'view',
      name: 'SpuList',
      route: '/spu/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/spu/spu-list.vue',
      inNav: true,
      permission: ['SPU分页查询'],
    },
  ],
}

export default spuRouter
