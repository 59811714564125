/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/11/2 11:09
 * @description: 地址路由
 */

const addressRouter = {
  route: null,
  name: null,
  title: '邮寄地址管理',
  type: 'folder',
  icon: 'iconfont el-icon-place',
  filePath: 'view/address/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '邮寄地址列表',
      type: 'view',
      name: 'Address',
      route: '/address/list',
      filePath: 'view/address/address-list.vue',
      inNav: true,
      icon: 'iconfont el-icon-document',
      permission: ['邮寄地址分页查询'],
    },
    {
      title: '创建邮寄地址',
      type: 'view',
      name: 'AddressCreate',
      route: '/address/add',
      filePath: 'view/address/address-create.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['创建邮寄地址'],
    },
  ],
}

export default addressRouter
