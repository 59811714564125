/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2024/7/8 17:02
 * @description: 店铺类型路由
 */

const merchantTypeRouter = {
  route: null,
  name: null,
  title: '店铺经营类别管理',
  type: 'folder',
  icon: 'iconfont el-icon-coordinate',
  filePath: 'view/merchant-type/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '经营类别列表',
      type: 'view',
      name: 'Tag',
      route: '/merchant-type/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/merchant-type/merchant-type-list.vue',
      inNav: true,
      permission: ['店铺经营类型分页查询'],
    },
    {
      title: '创建经营类别',
      type: 'view',
      name: 'TagCreate',
      route: '/merchant-type/add',
      icon: 'iconfont icon-add',
      filePath: 'view/merchant-type/merchant-type-create.vue',
      inNav: true,
      permission: ['创建店铺经营类型'],
    },
  ],
}

export default merchantTypeRouter
