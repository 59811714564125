/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/31 11:16
 * @description: sku路由
 */

const skuRouter = {
  route: null,
  name: null,
  title: 'SKU管理',
  type: 'folder',
  icon: 'iconfont el-icon-goods',
  filePath: 'view/sku/',
  sort: null,
  inNav: true,
  children: [
    {
      title: 'SKU列表',
      type: 'view',
      name: 'SkuList',
      route: '/sku/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/sku/sku-list.vue',
      inNav: true,
      permission: ['SKU分页查询'],
    },
  ],
}

export default skuRouter
