/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/9/21 14:02
 * @description: 店铺路由
 */

const merchantRouter = {
  route: null,
  name: null,
  title: '店铺管理',
  type: 'folder',
  icon: 'iconfont el-icon-s-shop',
  filePath: 'view/merchant/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '店铺列表',
      type: 'view',
      name: 'Merchant',
      route: '/merchant/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/merchant/merchant-list.vue',
      inNav: true,
      permission: ['店铺分页查询'],
    },
    {
      title: '创建店铺',
      type: 'view',
      name: 'MerchantCreate',
      route: '/merchant/add',
      icon: 'iconfont icon-add',
      filePath: 'view/merchant/merchant-create.vue',
      inNav: true,
      permission: ['创建店铺'],
    },
  ],
}

export default merchantRouter
