/**
 * Created by IntelliJ IDEA.
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2024/6/4 16:37:25
 * @description: 物流路由
 */

const logisticsRouter = {
  route: null,
  name: null,
  title: '物流管理',
  type: 'folder',
  icon: 'iconfont el-icon-bicycle',
  filePath: 'view/logistics/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '物流列表',
      type: 'view',
      name: 'Logistics',
      route: '/logistics/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/logistics/logistics-list.vue',
      inNav: true,
      permission: ['物流信息分页查询'],
    },
    {
      title: '物流操作',
      type: 'view',
      name: 'LogisticsOption',
      route: '/logistics/option',
      icon: 'iconfont icon-add',
      filePath: 'view/logistics/logistics-option.vue',
      inNav: false,
      permission: [],
    },
  ],
}

export default logisticsRouter
