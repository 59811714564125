/**
 * Created by IntelliJ IDEA.
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2024/6/4 17:31:44
 * @description: 快递路由
 */

const tzDeliveryRouter = {
  route: null,
  name: null,
  title: '快递公司管理',
  type: 'folder',
  icon: 'iconfont el-icon-timer',
  filePath: 'view/tz-delivery/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '快递公司列表',
      type: 'view',
      name: 'TzDelivery',
      route: '/tz-delivery/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/tz-delivery/tz-delivery-list.vue',
      inNav: true,
      permission: ['快递信息分页查询'],
    },
    {
      title: '创建快递公司',
      type: 'view',
      name: 'TzDeliveryCreate',
      route: '/tz-delivery/add',
      icon: 'iconfont icon-add',
      filePath: 'view/tz-delivery/tz-delivery-create.vue',
      inNav: true,
      permission: ['创建快递信息'],
    },
  ],
}

export default tzDeliveryRouter
