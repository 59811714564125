/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/4 16:31
 * @description: 商品描述路由
 */

const saleExplainRouter = {
  route: null,
  name: null,
  title: '商品描述管理',
  type: 'folder',
  icon: 'iconfont el-icon-reading',
  filePath: 'view/sale-explain/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '商品描述列表',
      type: 'view',
      name: 'SaleExplain',
      route: '/sale-explain/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/sale-explain/sale-explain-list.vue',
      inNav: true,
      permission: ['商品描述分页查询'],
    },
    {
      title: '创建商品描述',
      type: 'view',
      name: 'SaleExplainCreate',
      route: '/sale-explain/add',
      icon: 'iconfont icon-add',
      filePath: 'view/sale-explain/sale-explain-create.vue',
      inNav: true,
      permission: ['创建商品描述'],
    },
  ],
}

export default saleExplainRouter
