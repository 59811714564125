/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2024/6/24 18:09
 * @description: 协议路由
 */

const protocolRouter = {
  route: null,
  name: null,
  title: '协议管理',
  type: 'folder',
  icon: 'iconfont el-icon-files',
  filePath: 'view/protocol/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '协议列表',
      type: 'view',
      name: 'Protocol',
      route: '/protocol/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/protocol/protocol-list.vue',
      inNav: true,
      permission: ['协议分页查询'],
    },
    {
      title: '创建协议',
      type: 'view',
      name: 'ProtocolCreate',
      route: '/protocol/add',
      icon: 'iconfont icon-add',
      filePath: 'view/protocol/protocol-create.vue',
      inNav: true,
      permission: ['创建协议'],
    },
  ],
}

export default protocolRouter
