/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/6/25 10:45
 * @description: 商品评价路由
 */

const rateRouter = {
  route: null,
  name: null,
  title: '商品评价管理',
  type: 'folder',
  icon: 'iconfont el-icon-star-on',
  filePath: 'view/rate/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '商品评价列表',
      type: 'view',
      name: 'Rate',
      route: '/rate/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/rate/rate-list.vue',
      inNav: true,
      permission: ['商品评价分页查询'],
    },
    {
      title: '创建商品评价',
      type: 'view',
      name: 'RateCreate',
      route: '/rate/add',
      icon: 'iconfont icon-add',
      filePath: 'view/rate/rate-create.vue',
      inNav: true,
      permission: ['创建商品评价'],
    },
  ],
}

export default rateRouter
