/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/9/22 08:10
 * @description: 活动路由
 */

const activityRouter = {
  route: null,
  name: null,
  title: '活动管理',
  type: 'folder',
  icon: 'iconfont el-icon-notebook-1',
  filePath: 'view/activity/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '活动列表',
      type: 'view',
      name: 'activityList',
      route: '/activity/list',
      filePath: 'view/activity/activity-list.vue',
      inNav: true,
      icon: 'iconfont el-icon-discount',
      permission: ['活动分页查询'],
    },
    {
      title: '优惠券模板列表',
      type: 'view',
      name: 'couponTemplateList',
      route: '/coupon-template/list',
      filePath: 'view/coupon/coupon-template-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['优惠券模板分页查询'],
    },
  ],
}

export default activityRouter
