/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/5/4 16:31
 * @description: 标签路由
 */

const configurationRouter = {
  route: null,
  name: null,
  title: '配置管理',
  type: 'folder',
  icon: 'iconfont el-icon-set-up',
  filePath: 'view/configuration/',
  sort: null,
  inNav: true,
  children: [
    {
      title: '配置列表',
      type: 'view',
      name: 'Configuration',
      route: '/configuration/list',
      icon: 'iconfont el-icon-document',
      filePath: 'view/configuration/configuration-list.vue',
      inNav: true,
      permission: ['配置分页查询'],
    },
    {
      title: '创建配置',
      type: 'view',
      name: 'ConfigurationCreate',
      route: '/configuration/add',
      icon: 'iconfont icon-add',
      filePath: 'view/configuration/configuration-create.vue',
      inNav: true,
      permission: ['创建配置'],
    },
  ],
}

export default configurationRouter
